.ngx-datatable.material {
    box-shadow: none;
}
.datatable-body-cell.center,
.datatable-body-cell.center .datatable-body-cell-label {
    display: flex !important;
    align-items: center !important;
}
// .fullscreen {
//     position: absolute !important;
//     height: auto !important;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     width: 100%;
// }
