.plt-desktop {
    .responsive-product-list,
    .responsive-product-detail,
    .responsive-create-trip {
        margin: auto;
    }
    @media screen and (min-width: 600px) {
        .responsive-product-list,
        .responsive-product-detail {
            max-width: 885px !important;
        }
        .responsive-create-trip {
            max-width: 600px !important;
        }
        .col-responsive-form {
            max-width: 460px !important;
        }
    }
}
