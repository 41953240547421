.center-illustration {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.illustration {
    display: block;
    margin: 30px auto;
    width: 200px;
}

.illustration-text {
    margin: auto;
    max-width: 240px;
}
